.SnippetTopMenu {
    position: fixed;
    padding: 12px 10px 12px 16px;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255,255,255,1);
    z-index: 18;
    border-bottom: 1px solid #fff;
    display: flex;
    justify-content: space-between;
    align-items:center;
    height: 48px;
    /* backdrop-filter: blur(8px); */
}

.TopMenuContent {
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
}

.SnippetTopMenu .Logo {
    margin-left: 16px;
    position: relative;
    top: 0px;
}

.SnippetTopMenu .Logo,
.SnippetTopMenu .NewTextBtn {
    display: inline-block;
    vertical-align: middle;
}

.SnippetTopMenu .Logo {
    /* width: 80px; */
    margin-right: 12px;
    font-weight: 600;
}

.SnippetTopMenu .Logo a {
    text-decoration: none;
}

.SnippetTopMenu .Logo .Beta {
    font-size: 10px;
    position: relative;
    color: var(--active-color);
    top: -4px;
    left: 1px;
}

.SnippetTopMenu .LoginMenu {
    /* padding: 9px 12px; */
    right: 0;
    top: 0;
}

.SnippetTopMenu .RightMenu {
    margin-right: 23px;
}

.GlobalNav a {
    opacity: 0.7;
}

.GlobalNav a:hover {
    opacity: 1;
}