.NotEmail {
    background: red;
}

.InviteMembers {
    margin: 20px 0 10px;
}

.InviteFormGroupContainer {
    margin: 0 0 10px;
}

.InviteFormGroupContainer .bp3-form-group {
    margin: 0 0 5px;
}