.NewDocModal {
    width: 640px;
    height: 572px;
    position: relative;
    padding: 0;
}

.NewDocModal header {
    position: absolute;
    top: 0;
    left: 0;
    padding: 12px 60px;
    width: 100%;
    border-bottom: 1px solid #eee;
}

.NewDocModal header h2 {
    font-size: 22px;
    margin: 4px 0;
}

.NewDocModal .ModalSteps {
    margin: 63px 0 0;
    padding: 5px 60px 3px;
}

.NewDocModal .ModalBody {
    padding: 16px 60px 16px;
    overflow: scroll;
    max-height: 400px;
}

.NewDocModal .DocSettings {
    margin: 10px 0 0;
}

.EditingProcessContainer {
    border-radius: 8px;
}

.NewDocModal .action-buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 15px 60px;
    border-top: 1px solid #eee;
    width: 100%;
}

.NewDocModal .InviteMembers {
    margin-top: 0;
}

.NewDocModal .bp3-form-group label.bp3-label {
    font-size: 13px;
}

.NewDocModal .NewDecisionMakersNote {
    margin: 15px 0;
    cursor: default;
}

.NewDocModal .NewDecisionMakersNote svg {
    fill: var(--st-primary);
    transform: rotate(-33deg);
}