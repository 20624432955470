.AddComment {
}

.AddCommentInterface {
    display: flex;
    border-radius: 8px;
    width: 240px;
    position: fixed;
    box-shadow: var(--soft-shadow);
    background-color: #fff;
    /* height: 31px; */
}

.AddCommentInterface .bp3-input-group {
    box-shadow: 0;
    border-radius: 8px;
}

.AddCommentInterface .bp3-input {
    border-radius: 8px;
}

.AddCommentInterface:not(.active) textarea.bp3-input {
    height: 32px !important;
}

.AddCommentInterface textarea.bp3-input {
    resize: none;
    padding: 7px 10px;
}

.AddCommentInterface .bp3-input,
.AddCommentInterface .bp3-input:focus {
    outline: none;
    border: 0px;
    box-shadow: none;
}

.AddCommentInterface .SendCommentBtn {
    height: 22px;
    /* width: 23px;
    min-width: 21px; */
    min-height: 18px;
    margin: 4px;
    position: relative;
}

.AddCommentInterface .SendCommentBtn.bp3-button .bp3-icon svg {
    width: 10px;
}

.AddCommentInterface.active {
    display: block;
    height: auto;
}

.AddCommentInterface.active .CommentControls {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    border-top: 1px solid #eee;
}

.AddCommentInterface.active .CommentControls .SendCommentBtn {
    text-align: right;
    right: 0;
}

.bp3-overlay-backdrop.transparent-bg {
    background-color: transparent;
}

