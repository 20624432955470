.SummaryLine {
    width: 100%;
    text-align: left;
    border: 0px;
    background-color: var(--st-sidebar-bg);
    padding: 6px 15px 7px;
    margin: 7px 0 7px;
    cursor: pointer;
    /* transition: background-color 0.2s; */
}

.SummaryLine:hover {
    background-color: rgb(244,244,244);
}

.UnchangedSection .ShowMoreContainer {
    /* display: none; */
    /* opacity: 0; */
    position: relative;
    /* top: 10px; */
    transition: opacity 0.2s, top 0.2s;
}

.UnchangedSection:hover .ShowMoreContainer {
    /* display: block; */
    opacity: 1;
    top: 0;
}

.FadingBlocks {
    position: relative;
    cursor: pointer;
}

.FadingBlocks.prev {
    margin-bottom: -16px;
}

.FadingBlocks.next {
    margin-top: -16px;
}

.FadingBlocks .FadeNext, 
.FadingBlocks .FadePrev {
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 2;
    display: block;
    left: 0;
    height: calc(100%);
    opacity: 1;
    transition: opacity 0.3s;
}

.FadingBlocks .FadePrev {
    background-image: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,0.8), rgba(255,255,255,1));
    margin-bottom: 32px;
}

.FadingBlocks .FadeNext {
    background-image: linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,0.8), rgba(255,255,255,1));
    /* padding-bottom: 32px; */
}

.UnchangedSection:hover .FadePrev,
.UnchangedSection:hover .FadeNext {
    /* opacity: 0.8; */
    /* padding-bottom: 32px; */
}

.ShowMoreContainer button {
    /* width: 50%; */
    margin: 0 auto 0 20px;
    padding-left: 32px;
    padding-right: 32px;
    display: block;
    text-align: center;
}