.SnippetDoc .SnippetDocNav {
    margin: 24px 0px 16px 20px;
    border-bottom: 1px solid #ddd;
}

.SnippetDocNavItemContainer {
    display: inline-block;
    margin-right: 0px;
}

.SnippetDocNav a {
    color: #000;
    opacity: 0.9;
    padding: 8px 12px 6px;
    border-radius: 6px 6px 0 0;
    font-weight: 600;
    display: inline-block;
    text-decoration: none;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 2px solid transparent;
    margin-bottom: -2px;
    font-size: 12px;
    /* font-weight: 500; */
}

.SnippetDocNav a:hover {
    /* border-bottom: 1px solid var(--st-primary); */
    opacity: 1;
}

.SnippetDocNav a.active {
    opacity: 1;
    border-bottom: 1px solid #fff;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    margin-bottom: -1px;
}

.SnippetDocNavItemContainer .SnippetDocNavItemCount {
    background: #eee;
    margin-left: 3px;
    border-radius: 20px;
    padding: 1px 4px;
    /* font-weight: 400; */
    font-size: 9px;
    position: relative;
    top: -1px;
}

.SnippetDocNav .SnippetDocNavItemContainer a .SnippetDocNavItemCount.active {
    background-color: var(--active-color);
    color: #fff;
}

.SnippetDocNavItemContainer a.active .SnippetDocNavItemCount {
    color: #333;
}

@media screen and (max-width: 600px) {
    .SnippetDoc .SnippetDocNav {
        border-bottom: 0px;
    }
    .SnippetDocNavItemContainer {
        display: block;
    }
    .SnippetDocNav a {
        border-radius: 0;
        border-left: 1px solid #ddd;
        width: 100%;
        opacity: 0.7;
    }
    .SnippetDocNav a.active {
        border: none;
        border-left: 3px solid var(--active-color);
        padding-left: 10px;
        opacity: 1;
    }
}