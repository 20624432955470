.ProtocolCard {
    padding: 0px;
    box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 2px 4px;
    border-radius: 6px;
    margin: 24px 20px 12px;
    max-width: 640px;
    position: relative;
}

.ProtocolCard * {
    font-size: 12px;
}

.ProtocolCallout {
    margin: 0;
    padding: 4px 21px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding-bottom: 4px;

}

.ProtocolCallout:last-of-type:not(:first-of-type) {
    border-top: 1px solid #ddd;
}

.ProtocolCallout .CalloutInfo {
    background-color: #fff;
    padding: 12px 64px 12px 8px;
    border-radius: 4px;
}

.ProtocolCard .EditProtocolBtn {
    position: absolute;
    top: 10px;
    right: 10px;
}

.DocSettingsModal {
    width: 640px;
}

.DocSettingsModal .SnippetControls {
    margin: 0;
    padding: 0;
}