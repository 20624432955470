.InlineToolbarContainer {
    position: fixed;
    z-index: 999;
    background-color: transparent;
    padding-bottom: 20px;
}

.InlineToolbarHandle {
    background: none;
    pointer-events: none;
}

.InlineToolbar {
    padding: 4px 8px;
    border-radius: 8px;
    top: 20px;
    left: 20px;

    background: #fff;
    box-shadow: var(--soft-shadow);
}

.InlineToolbar a {
    color: var(--active-color);
    text-decoration: underline;
    margin-right: 4px;
}

.InlineToolbar a:hover {
    color: var(--active-color-hover);
}

.InlineToolbar button {
    border: 0;
    cursor: pointer;
    padding: 1px 4px;
    background-color: transparent;
    opacity: 0.6;
    margin-left: 2px;
}

.InlineToolbar button:hover {
    background-color: #eee;
    border-radius: 4px;
    opacity: 1;
}

.LinkToolbarPopover {
    box-shadow: none;
}

.LinkToolbarPopover .InlineToolbar {
    box-shadow: var(--soft-shadow);
    padding-right: 4px;
}

.InlineToolbarPortal .bp3-overlay-start-focus-trap,
.InlineToolbarPortal .bp3-overlay-end-focus-trap {
    display: none;
}

.ContainedLink {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 300px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px;
}

.LinkToolbarTooltip {
    position: relative;
    z-index: 99;
}