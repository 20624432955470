.ThreadTooltip {
    display: flex;
    background-color: #fff;
    padding: 12px;
    border-radius: 8px;
    font-size: 12px;
    gap: 16px;
    width: 240px;
    box-shadow: var(--double-shadow);
    cursor: pointer;
}

.ThreadTooltipPopover {
    position: relative;
    top: -4px;
    box-shadow: none;
    /* border-radius: 8px; */
}

.ThreadTooltip .avatar {
    cursor: pointer;
}

.ThreadPreview .avatar.SMALL .initial {
    top: 1px;
}

.ThreadReplies {
    display: block;
    margin-top: 4px;
    cursor: pointer;
}