.VotingSummary {
    margin: 10px 0 0px 0;
    padding-top: 10px;
    /* display: flex; */
    /* justify-content: space-evenly; */
    gap: 16px;
    border-top: 1px dotted #ddd;
}

.VotingSummary .VotingBlock {
    flex-grow: 1;
    display: inline-block;
}

.VotingSummary .Voter {
    padding: 4px 8px;
    border-radius: 8px;
    display: inline-block;
    background-color: var(--neutral-dark);
    color: #fff;
    /* border-bottom: 1px solid #eee; */
    font-weight: 500;
    font-size: 11px;
}

.VotingSummary .VotesFor .Voter {
}

.VotingSummary .VotesAgainst .Voter {
    background-color: var(--offwhite-hover);
    color: #000;
}
