.EditingProcessOption {
    width: 100%;
    margin: 0 0 12px 0;
    display: block;
    background-image: none;
    border-radius: 8px;
    box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 2px 4px;
    background-color: #fff;
    padding: 0 0 8px;
}

.EditingProcessOption:hover,
.EditingProcessOption.selected {
    box-shadow: rgb(15 15 15 / 15%) 0px 0px 0px 1px, rgb(15 15 15 / 15%) 0px 2px 4px;
}

.EditingProcessOption.selected {
    outline: 1px solid rgb(128, 159, 251);
}

.EditingProcessOption .ToggleContainer {
    margin: 0px 0;
}

.EditingProcessOptionToggle {
    padding: 16px 24px 8px;
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    text-align: left;
    border: none;
    cursor: pointer;
    background-color: transparent;
}

.EditingProcessOption h3,
.EditingProcessOption p {
    margin: 0;
}