.bp3-button.BlockTypeBtn {
    display: block;
    text-align: left;
    padding: 4px 10px;
}

.bp3-button.BlockTypeBtn.unstyled .bp3-button-text { 
    font-weight: 400; 
    font-size: 1rem;
}

.bp3-button.BlockTypeBtn.header-one {
    padding: 0px 10px;
}

.bp3-button.BlockTypeBtn.header-one,
.bp3-button.BlockTypeBtn.header-one .bp3-button-text { 
    font-size: 1.7rem; 
    font-weight: 700; 
}

.bp3-button.BlockTypeBtn.header-two, 
.bp3-button.BlockTypeBtn.header-two .bp3-button-text { 
    font-size: 1.35rem; 
    font-weight: 700; 
}

.bp3-button.BlockTypeBtn.header-three,
.bp3-button.BlockTypeBtn.header-three .bp3-button-text { 
    font-size: 1.15rem; font-weight: 700; 
}

.bp3-button.BlockTypeBtn.header-four,
.bp3-button.BlockTypeBtn.header-four .bp3-button-text { 
    font-size: 1em; font-weight: 700; 
}
.bp3-button.BlockTypeBtn.header-five,
.bp3-button.BlockTypeBtn.header-five .bp3-button-text { 
    font-size: 0.83em; font-weight: 700; 
}

.bp3-button.BlockTypeBtn.header-six,
.bp3-button.BlockTypeBtn.header-six .bp3-button-text { 
    font-size: 0.67em; font-weight: 700; 
}

.bp3-button.BlockTypeBtn.blockquote .bp3-button-text { 
    border-left: 3px solid #ddd;
    padding-left: 12px;
    font-size: 1rem;

}

.CurrentBlockType .bp3-button-text {
    min-width: 80px;
    font-weight: 400;

}