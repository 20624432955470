.avatar {
    width: 28px;
    height: 28px;
    border-radius: 28px;
    background-color: #fff;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    padding: 5px;
    transition: margin-right 0.2s, box-shadow 0.2s;
    cursor: pointer;
}

.cursor-default .avatar {
    cursor: default;
}

.avatar.image-avatar {
    padding: 0;
}

.avatar.not-logged-in {
    color: #777;
    background-color: #eee;
    box-shadow: none;
}

.avatar .initial {
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 8px;
    opacity: 0.8;
    transition: color 0.2s;
    font-weight: 700;

}

.avatar.SMALL {
    width: 22px;
    height: 22px;
    font-size: 11px;
}

.avatar.SMALL.huddled {
    margin-right: -12px;
}

.avatar.SMALL .initial {
    top: 4px;
    left: 0px;
}

.avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100px;
}