.highlight {
  position: 'absolute';
  background-color: rgba(255, 228, 92, 0.4);
  cursor: pointer;
  z-index: 2;
  mix-blend-mode: multiply;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.highlight:hover {
  background-color: rgba(255, 228, 92, 0.9);
}