.EmojiPalette {
    position: absolute;
    background-color: #fff;
    z-index: 9;
    width: 200px;
    padding: 8px 12px;
    border-radius: 8px;
    right: 40px;
    box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 2px 4px;
}

.EmojiPalette .bp3-button {
    font-size: 18px;
    padding: 3px 6px;
    border-radius: 4px;
}

.EmojiPalette .bp3-button:hover {
    background-color: #eee;
}