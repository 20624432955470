.TableOfContents {
    margin: 24px 0 24px 0px;
    padding: 12px 22px 24px;
    max-height: 66vh;
    overflow: scroll;
    background-color: rgb(250, 250, 250);
    position: sticky;
    box-shadow: none;
    border-radius: 8px;
    top: 14vh;
}

.TableOfContents a {
    display: block;
    opacity: 0.8;
}
