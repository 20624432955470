.SnippetProposals .NonIdealBlock {
    display: block;
    text-align: left;
    justify-content: left;
    padding: 0 24px;
    background-color: transparent;
}

.SnippetProposals .NonIdealBlock h4 {
    font-size: 12px;
    margin-bottom: 8px;
}