.BlockDiff {
    padding: 0;
    min-height: 1.4em;
    position: relative;
    padding-left: 20px;
    padding-right: 28px;
    border-radius: 8px;
    margin-bottom: 1px;
}

.BlockDiff p {
    margin: 0;
}

.BlockDiff blockquote {
    border-left: 1px solid #ddd;
    padding: 0px 30px;
    margin: 0;
}

.StringDiff .added,
.StringDiff .removed {
    border-radius: 4px;
    padding: 0 4px;
}

.BlockDiff.added,
.BlockDiff.added .Content,
.StringDiff .added  {
    background: #b0e6cf;
    color:rgb(0, 53, 20);
}

.BlockDiff.removed,
.BlockDiff.removed .Content,
.BlockDiff.removed .Content a,
.StringDiff .removed,
.StringDiff .removed a {
    background: rgb(253, 236, 232);
    color: #f88b8b;
    text-decoration: line-through;
}

.BlockDiff.added .Content a,
.StringDiff .added a,
.StringDiff .removed a {
    color: var(--active-color)
}

.BlockDiff.removed .Content a,
.StringDiff .added a,
.StringDiff .removed a {
    text-decoration: line-through underline;
}

.BlockDiff.added .Content a:hover,
.StringDiff .added a:hover {
    color: var(--active-color-hover);
}

.BlockDiff.removed .Content a:hover,
.StringDiff .removed a:hover {
    color: #f88b8b;
}

.BlockDiff.unchanged {
    color: rgb(105, 105, 105);
}

.BlockDiff.conflict {
    border: 1px dotted var(--warning-color);
    padding: 4px 10px;
    margin-bottom: 4px;
    border-radius: 0;
}

.BlockDiff.conflict .bp3-icon {
    margin-right: 8px;
    color: var(--warning-color);
    display: none;
}

.BlockDiff.conflict .bp3-icon,
.BlockDiff.conflict p {
    display: inline-block;
    color: var(--warning-color);
}

.BlockDiff.interactive.modified:hover,
.BlockDiff.interactive.added:hover,
.BlockDiff.interactive.removed:hover {
    background: var(--block-hover-bg);
}

/* .BlockDiff:not(.unchanged):hover {
    background-color: rgb(249, 249, 249);
}

.BlockDiff.added:hover,
.BlockDiff.added:hover .Content {
    background: rgb(176, 247, 192);
}

.BlockDiff.removed:hover,
.BlockDiff.removed:hover .Content {
    background: rgb(250, 227, 223);
} */

/* List Styles */

.BlockDiff.unordered-list-item,
.BlockDiff.ordered-list-item {
    margin: 4px 0 0 0;
}

.BlockDiff ul,
.BlockDiff ol {
    margin: 0 0 0 8px;
}

.BlockDiff ul li,
.BlockDiff ol li {
    padding-left: 14px;
}

.TextBlock.unordered-list-item li,
.TextBlock.ordered-list-item li {
    padding-left: 10px;
    font-size: 15px;
}

.BlockDiff.unordered-list-item + .BlockDiff.unordered-list-item,
.BlockDiff.ordered-list-item + .BlockDiff.ordered-list-item {
    margin: 0;
}

.BlockDiff.unordered-list-item + .BlockDiff:not(.unordered-list-item),
.BlockDiff.ordered-list-item + .BlockDiff:not(.ordered-list-item) {
    margin: 4px 0 0;
}

.BlockDiff a {
    color: var(--active-color);
    text-decoration: underline;
}

.BlockDiff a:hover {
    color: var(--active-color-hover);
}