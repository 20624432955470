.DocReadView .TextBody {
    max-width: 620px;
    width: 620px;
}

.DocReadView .TextBlock {
    padding: 0;
}

.DocReadView .TextBlock h1,
.DocReadView .TextBlock h2,
.DocReadView .TextBlock h3,
.DocReadView .TextBlock h4,
.DocReadView .TextBlock h5,
.DocReadView .TextBlock h6 {
    line-height: 1.6;
}

.DocReadView .TextBlock h1 {
    font-size: 1.875rem;
    letter-spacing: -0.03rem;
    margin: 0;
    line-height: 1.4;
}

.DocReadView .TextBlock h2 {
    font-size: 1.45rem;
    margin: 0;
    letter-spacing: -0.01rem;
}

.DocReadView .TextBlock h3 {
    font-size: 1.2rem;
    margin: 0;
    letter-spacing: -0.02rem;
}

.DocReadView .TextBlock h4 {
    font-size: 1.2rem;
    margin: 0rem 0 0rem;
    letter-spacing: -0.02rem;
}

.DocReadView .TextBlock h5 {
    font-size: 1.1rem;
    margin: 0rem 0 0rem;
    letter-spacing: -0.02rem;
}

.DocReadView .TextBlock h6 {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 0rem 0 0rem;
    letter-spacing: -0.02rem;
}

.DocReadView .TextBlock ul, 
.DocReadView .TextBlock ol {
    margin: 4px 0 0 16px;
}

.DocReadView .TextBlock li {
    padding-left: 8px;
}

.DocReadView .TextBlock ul ul, 
.DocReadView .TextBlock ol ol {
    margin-left: 32px;
}

.DocReadView .TextBlock p,
.DocReadView .TextBlock blockquote,
.DocReadView .TextBlock li {
    font-size: 1.1rem;
    line-height: 140%;
    letter-spacing: -0.001rem;
}

.DocReadView .TextBlock blockquote {
    padding: 0 24px;
    margin: 8px 4px;
    border-left: 3px solid #ddd;
}