.BlockFeedback {
    /* margin-right: 28px; */
    margin-left: 20px;
}

.feedback-label {
    margin-left: 4px;
    /* font-weight: 600; */
    font-size: 11px;
}

.date-summary {
    font-size: 11px;
    margin-left: 8px;
    opacity: 0.6;
}

.CommentsToggle {
    background-color: transparent;
    padding: 0px;
    border: none;
    /* color: var(--active-color); */
    cursor: pointer;
    opacity: 1;
}

.CommentsToggle:hover {
    opacity: 1;
}

.Comments {
    /* border-left: 1px dotted #ddd;
    padding-left: 16px; */
    margin: 12px 0 12px;
}

.BlockFeedback .Comments .Comment {
    border-bottom: 1px solid #eee;
    padding: 16px 0;
}

.BlockFeedback .Comments .Comment:first-of-type {
    padding-top: 0;
}

.BlockFeedback .Comments .Comment:last-of-type {
    border-bottom: 0;
}