.ProposalMenu {
    padding: 10px 15px;
    width: 250px;
    position: relative;
}

.DocEditorModal {
    padding: 0;
    border-radius: 8px;
    width: 960px;
    /* margin: 0 0 0 50px; */
    height: 88vh;
    display: block;
    box-shadow: 0 1px 0px rgba(0,0,0,0.1);
    border: 1px solid #ddd;
}

.DocEditorModal .EditorColumn, 
.DocEditorModal .ChangeActionColumn {
    display: inline-block;
}

.DocEditorModal .EditorColumn {
    width: 640px;
    border-right: 1px solid #ddd;
    height: 100%;
    vertical-align: top;
}

.DocEditorModal .EditorColumnHeader {
    padding: 4px 30px;
    border-bottom: 1px solid #eee;
}

.DocEditorModal .ChangeActionColumn {
    width: 159px;
    vertical-align: bottom;
    padding: 8px 24px 24px;
    width: 318px;
}

.DocEditorModal .ActionButtons button {
    margin-left: 5px;
}

.DocEditorModal h2 {
    margin: 0 0 10px;
}

.EditorToolbar {
    padding: 8px 24px;
    border-bottom: 1px solid #eee;
}

.draft-link {
    color: var(--active-color);
    text-decoration: underline;
    cursor: text;
}

.draft-link:hover {
    color: var(--active-color-hover);
    cursor: text;
}