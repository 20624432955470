.ProposalCard {
    padding: 16px 16px 16px 22px;
}

.ProposalCards:last-child {
    border-bottom: none;
}

.ProposalCard:hover {
    cursor: pointer;
    /* background-color: #fafafa; */
}

.ProposalCard .CurrentSupport,
.ProposalCard .MainInfo {
    vertical-align: middle;
}

.ProposalCardHeader {
    display: flex;
    gap: 16px;
    justify-content: space-between;
}

.ProposalCardHeader .StatusTag {
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 600;
    border-radius: 8px;
    padding: 4px 8px;
}

.ProposalCardHeader h3 {
    flex-grow: 2;
    max-width: 75%;
}

.ProposalCard .CurrentSupport {
    width: 60px;
    text-align: center;
    margin: 0;
    font-size: 22px;
    opacity: 0.8;
}

.ProposalCard .CurrentSupport.bigNumber {
    font-size: 15px;
    font-weight: 500;
}

.ProposalCard .CurrentSupport.noSupport {
    opacity: 0.4;
}

.ProposalCard h3 {
    margin: 0 0;
}

.ProposalCard p {
    margin-bottom: 0;
}

.ProposalCard .Supporters {
    margin-top: 10px;
}

.ProposalCard .Supporters .Supporter {
    font-size: 10px;
}

.ProposalCard .Supporters .Supporter .bp3-icon {
    margin: 0;
    position: relative;
    top: 1px;
    left: -1px;
}

.ProposalCard .Supporters .Supporter svg {
    width: 12px;
}

.ProposalVotes {
    display: inline-block;
}

.ProposalVotes .VoteIndicator {
    display: inline-block;
    opacity: 0.6;
    font-size: 12px;
}

.ProposalVotes .VoteIndicator.half-opaque {
    opacity: 0.3;
}

.ProposalCard .VotingSummary {
    border-top: none;
    padding: 0;
}