.EditingProcessGoBack {
    background: transparent;
    padding: 0;
    border: none;
    display: block;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    opacity: 0.8;
}

.EditingProcessGoBack:hover {
    opacity: 1;
}

.EditingProcessOptionControls {
    width: 100%;
    margin: 10px 0 3px;
    padding: 0;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 2px rgba(0,0,0,0.1);
    background-color: #fff;
}

.EditingProcessOptionControls .ProcessSummary h3,
.EditingProcessOptionControls .ProcessSummary p {
    margin: 0;
}

.EditingProcessOption .ProcessSettings {
    padding: 0px 24px;
}

.EditingProcessOption .ProcessSettings ul {
    margin: 0;
    padding: 0 0 0 16px;
}

.EditingProcessOption .ProcessSettings ul li {
    margin: 8px 0;
}

.InlineControl {
    width: 70px;
    display: inline-block;
    margin: 0 7px;
}

.EmailsFormGroup {
    margin: 10px 0;
}

.EscapeHatchBtn {
    background-color: #fff;
    border: none;
    padding: 0;
    font-weight: 700;
    font-size: 12px;
    cursor: pointer;
    opacity: 0.8;
}

.EscapeHatchBtn:hover {
    opacity: 1;
}