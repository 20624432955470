@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --st-primary: #4362fc;
  --st-primary-hover: #3555f7;
  --st-primary-disabled: #7388f1;
  --success: #b0e6cf;
  --neutral-dark: #4A4545;
  --neutral-dark-hover: hsl(0, 5%, 24%);
  --neutral-light: #5D5656;
  --neutral-light-hover: hsl(0, 6%, 31%);
  --interface-blue: #caddec;
  /* --active-color:  rgb(78, 110, 253); 
  --active-color-hover: rgb(45, 83, 253); 
  --active-color-pressed:  rgb(31, 69, 238);  */
  --active-color:  #725c9d; 
  --active-color-hover: hsl(260, 32%, 46%); 
  --warning-color: #ffa7a7;
  --dark-warning-color: #ff5a5a;
  --white-hover: rgb(238, 238, 239);
  --success-bg: rgb(195, 252, 208);
  --fail-bg: rgb(253, 236, 232);
  --soft-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 2px 4px;
  --double-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 20%) 0px 4px 6px;
  --mobile-breakpoint: 600px;
  --offwhite: #f4f3f1;
  --offwhite-active: #e3dfd8;
  --offwhite-hover: #e3dfd8;
  --deep-purple: hsl(259, 20%, 27%); 
  --offblack: rgb(26, 26, 32);
  --offblack-hover: rgb(50, 50, 51);
}

@layer utilities {
  .bg-offwhite { background-color: var(--offwhite); }
  .bg-offwhite-hover { background-color: var(--offwhite-hover); }
  .border-offwhite { border-color: var(--offwhite); }
  .border-offwhite-hover { border-color: var(--offwhite-hover); }
  .border-primary { border-color: var(--active-color); }
  .text-deep-purple { color: var(--deep-purple); }
}

@font-face {
  font-family: 'Inter';
  src: url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');
}

@font-face {
  font-family: "ABC Diatype Regular";
  src: url("fonts/ABCDiatype-Regular.woff") format('woff');
}

@font-face {
  font-family: "ABC Diatype Bold";
  src: url("fonts/ABCDiatype-Bold.woff") format('woff');
}

body, * {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  /* font-style: normal; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* letter-spacing: -0.035em; */
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  margin: 8px 0;
}

/* h3 {
  font-size: 18px;
} */
/* 
h4 {
  font-size: 16px;
} */

h1, h2, h3, h4, h5, h6, strong {
  font-weight: 700;
}

h1 {
  font-weight: 700;
  font-size: 36px;
  letter-spacing: -0.01em;
  line-height: 1.125;
}

h6 {
  font-size: 10px;
}

p {
  /* font-weight: 400; */
  font-size: 15px;
  line-height: 1.6;
}

a {
  color: #000;
}

a:hover {
  color: #000;
}

ol, ul, menu {
  list-style: revert-layer;
}

.small-checkbox {
  font-size: 12px;
  font-weight: 400;
}

.inline-block {
  display: inline-block;
}

.underline {
  text-decoration: underline;
}

.up1 {
  position: relative;
  top: -1px;
}

.padding-l-16 { padding-left: 16px; }
.border-l-light { border-left: 1px solid #ccc; };


@keyframes fadeSlide {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}