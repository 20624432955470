.TextBlock {
    padding: 0px 20px;
    margin: 0;
    position: relative;
    line-height: 1.6;
    min-height: 1.75em;
}

.TextBlock:hover {
    /* background-color: rgb(249, 249, 249); */
    border-radius: 8px;
}

.TextBlock p {
    margin: 0;
}

.TextBlock *::selection {
    background-color: var(--interface-blue);
}

.TextBlock blockquote {
    border-left: 3px solid #ddd;
    padding: 0px 24px;
    margin: 8px 4px;
}

.TextBlock a {
    color: var(--active-color);
    text-decoration: underline;
}

.TextBlock a:hover {
    color: var(--active-color-hover);
}

.SnippetDocText .TextBlock.header-one h1 {
    font-size: 1.7rem;
    letter-spacing: -0.03rem;
    margin: 0;
    line-height: 1.4;
}

.SnippetDocText .TextBlock.header-two h2 {
    font-size: 1.35rem;
    margin: 0;
    letter-spacing: -0.01rem;
}

.SnippetDocText .TextBlock.header-three h3 {
    font-size: 1.15rem;
    margin: 0;
    letter-spacing: -0.02rem;
}


/* List Styles */

.TextBlock.unordered-list-item,
.TextBlock.ordered-list-item {
    margin: 4px 0 0;
}

.TextBlock.unordered-list-item,
.TextBlock.ordered-list-item {
    padding-left: 20px;
}

.TextBlock.unordered-list-item li,
.TextBlock.ordered-list-item li {
    padding-left: 8px;
    font-size: 15px;
}

.TextBlock.unordered-list-item + .TextBlock.unordered-list-item,
.TextBlock.ordered-list-item + .TextBlock.ordered-list-item {
    margin: 0;
}

.TextBlock.unordered-list-item + .TextBlock:not(.unordered-list-item),
.TextBlock.ordered-list-item + .TextBlock:not(.ordered-list-item) {
    margin: 4px 0 0;
}

.TextBlock.unordered-list-item ul,
.TextBlock.ordered-list-item ol {
    margin: 4px 0 0 16px;
}

.TextBlock.unordered-list-item ul ul,
.TextBlock.ordered-list-item ol ol {
    margin: 4px 0 0 24px;
}

.TextBlock .highlights {
    /* opacity: 0; */
    transition: opacity 0.3s;
}

.TextBlock:hover .highlights {
    /* opacity: 1; */
}

.highlight {
    position: 'absolute';
    background-color: rgba(255, 228, 92, 0.4);
    cursor: pointer;
    z-index: 2;
    mix-blend-mode: multiply;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .highlight:hover {
    background-color: rgba(255, 228, 92, 0.9);
  }

@media screen and (max-width: 600px) {
    .ThreadSidebar {
        display: none;
    }
}