.Authorship {
    display: flex;
    align-items: baseline;
}

.avatars {
    position: relative;
    top: 4px;
}

.avatars:hover .avatar {
    margin-right: 4px;
}
