.SignIn {
    width: 325px;
    margin: 24vh auto;
}

.SignIn h1 {
    margin: 8px 0 16px;
}

.SignIn p {
    margin: 24px 0 16px;
}