.LinkButton {
    border: none;
    background: none;
    color: black;
    opacity: 0.6;
    cursor: pointer;
}

.LinkButton:hover {
    text-decoration: underline;
    opacity: 1;
}

.LinkButton.small {
    font-size: 12px;
}