.ControlGroup {
    display: inline-block;
    padding-right: 4px;
    margin-right: 4px;
    border-right: 1px solid #eee;
}

.ControlGroup:last-of-type {
    border-right: none;
}

.ControlGroup button {
    margin-right: 2px;
}

.ControlGroup button:last-of-type {
    margin-right: 0;
}