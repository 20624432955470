.DocVersion {
    margin-top: 24px;
}

.DocVersion .VersionHeader {
    border-bottom: 1px dotted #ddd;
    padding-bottom: 12px;
    margin-bottom: 24px;
}

.DocVersion .DocTextDiff .BlockDiff,
.DocVersion .StaticVersion .TextBlock {
    /* padding: 0; */
    max-width: 672px;
    position: relative;
    left: -20px;
}

.DocVersion .StaticVersion .TextBlock:hover {
    background-color: transparent;
}

.DocVersion .BlockDiff.modified:hover {
    background-color: transparent;
}

.DocVersion .BlockDiff.added:hover,
.DocVersion .BlockDiff.added:hover .Content {
    background-color: rgb(195, 252, 208);
}

.DocVersion .BlockDiff.removed:hover,
.DocVersion .BlockDiff.removed:hover .Content {
    background-color: rgb(253, 236, 232);
}

.DocVersion .VotingSummary {
    margin: 12px 0 0;
    padding-top: 12px;
}

.DocVersion .VersionContext {
    display: flex;
    gap: 8px;
    max-width: 400px;
    width: 400px;
    margin-bottom: 16px;
}

.DocVersion .VersionContext .content-card {
    width: 200px;
}

.DocVersion .ArweaveLogo {
    position: relative;
    top: 3px;
}