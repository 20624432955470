.PostParticipate {
    min-height: 49px;
    display: block;
    border-radius: 3px;
    padding: 10px 22px 20px;
    width: 100%;
}

.PostParticipate.bp3-callout.bp3-callout-icon > .bp3-icon {
    top: 14px;
    left: 15px;
    color: var(--st-primary);
}

.Participate .SupportAction {
    padding: 0 20px 10px;
}

.Participate .SupportAction h4 {
    margin: 0 auto 3px;
}

.Participate .bp3-button {
    margin-bottom: 5px;
}