.EditProfileContent {
    display: flex;
    padding: 16px 12px;
    gap: 8px;
}

.EditProfileContent .EditProfileFields {
    flex-grow: 1;
}

.EditProfilePicture {
    display: flex;
    flex-direction: column;
    min-width: 160px;
    text-align: center;
}

.EditProfilePicture .ProfilePicturePreview {
    width: 120px;
    height: 120px;
    border-radius: 120px;
    background-color: #eee;
    margin: 16px auto 13px;
}

.ProfilePicturePreview img {
    object-fit: cover;
    width: 120px;
    height: 120px;
    border-radius: 120px;
}

.ProfilePicturePreview svg {
    margin-top: 24px;
}

input[type="file"] {
    display: none;
}

.file-upload-btn {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    border: 1px solid #ddd;
    border-radius: 3px;
}

.file-upload-btn:hover {
    background-color: #f6f6f6;
}

