.DocHistory {
    border-left: 1px solid #ddd;
    padding: 0 16px 0 32px;
    position: relative;
}

.TimelineCardContainer.new-date {
    margin-top: 24px;
    position: relative;
}

.TimelineMarker {
    width: 15px;
    height: 15px;
    background-color: #bbb;
    border-radius: 16px;
    position: absolute;
    top: 2px;
    left: -40px;
    border: 3px solid #fff;
}

.TopLineCover {
    width: 2px;
    height: 4px;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: -1px;
}

.timeline-label {
    margin-bottom: -4px;
}

