.bp3-label {
    font-weight: 400;
    font-size: 12px;
    letter-spacing: -0.015rem;
}

.bp3-form-group label.bp3-label {
    margin-bottom: 4px;
}

.bp3-form-group.large-input label.bp3-label,
.bp3-form-group.large-input input {
    font-size: 14px;
}

.bp3-form-group.large-input input {
    height: 32px;
}

.bp3-skeleton {
    border-radius: 8px;
}

.bp3-button-text {
    font-weight: 600;
    font-size: 12px;
}

.bp3-button.bp3-large {
    padding: 4px 20px;
    min-height: 36px;
}

.bp3-button.bp3-large .bp3-button-text {
    font-size: 14px;
}

.bp3-button.bp3-minimal {
    border-radius: 4px;
}

.bp3-button.bp3-minimal:not(.font600) .bp3-button-text {
    font-weight: 600;
}

.bp3-button.bp3-minimal:hover {
    background-color: rgb(237, 237, 237)
}

.bp3-button.bp3-minimal.bp3-active,
.bp3-button.bp3-minimal.bp3-active:hover {
    background-color: rgb(207, 207, 207)
}

.bp3-control, 
.bp3-input-ghost,
.bp3-input-group .bp3-input,
textarea.bp3-input,
textarea.bp3-input::placeholder {
    font-weight: 400;
    font-size: 12px;
}

.bp3-input::placeholder {
    color: rgb(134, 134, 134);
}

textarea.bp3-input::placeholder {
    color: #9da9b3;
}

.bp3-input {
    box-shadow: none;
    border: 1px solid #ddd;
}

.bp3-input-group.bp3-intent-danger .bp3-input {
    box-shadow: none;
    border: 1px solid var(--dark-warning-color);
}

.bp3-input-group.bp3-intent-danger .bp3-input:focus {
    border: 1px solid var(--dark-warning-color);
    box-shadow: 0 0 0 1px var(--dark-warning-color);
}

.bp3-form-group.bp3-intent-danger .bp3-input {
    border: 1px solid var(--warning-color);
}

.bp3-input:focus {
    border: 1px solid var(--active-color);
    box-shadow: 0 0 0 1px var(--active-color);
}

.bp3-tag-input.bp3-active {
    border: 1px solid var(--active-color);
    box-shadow: 0 0 0 1px var(--active-color);
}

.bp3-tag-input-values {
    font-weight: 600;
}

.public-DraftEditorPlaceholder-root,
.bp3-editable-text-placeholder > .bp3-editable-text-content {
    color: rgba(92, 112, 128, 0.6);
}

/* TAGS */

.bp3-tag.bp3-intent-primary {
    background-color: var(--active-color);
    color: #fff;
}

.bp3-tag {
    border-radius: 8px;
    padding: 2px 8px;
    font-weight: 600;
    letter-spacing: -0.02em;
    background-color: #d2dee7;
    color: #20352c;
}

.bp3-tag.bp3-intent-success {
    background-color: #b0e6cf;
    color: #20352c;
}

.bp3-tag.bp3-intent-danger {
    background-color: #f88b8b;
    color: #20352c;
}

.bp3-tag.neutral-dark {
    background-color: var(--neutral-dark);
    color: #fff;
}

.bp3-tag.neutral-light {
    background-color: var(--offwhite-hover);
    color: #000;
}

/* TOOLTIPS */

.bp3-tooltip2 {
    border-radius: 16px;
    box-shadow: 0 0 0 1px rgb(16 22 26 / 5%), 
                0 2px 4px rgb(16 22 26 / 5%), 
                0 4px 8px rgb(16 22 26 / 10%);
}

.bp3-tooltip2 .bp3-popover2-content {
    background-color: #000;
    border-radius: 16px;
    font-size: 12px;
    letter-spacing: -0.01rem;
    font-weight: 600;
    text-align: center;
    padding: 4px 16px;
}

.bp3-tooltip2 .bp3-popover2-content p {
    margin: 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.3;
}

.bp3-tooltip2 .bp3-popover2-content .tooltip-caption {
    margin: 0;
    opacity: 0.75;
    font-size: 12px;
    font-weight: 500;
}

.bp3-tooltip2 .bp3-popover2-arrow-border,
.bp3-tooltip2 .bp3-popover2-arrow-fill {
    fill: #000;
}

.bp3-tooltip2.small-tooltip .bp3-popover2-content {
    padding: 4px 14px;
}

.bp3-popover2-placement-top {
    position: relative;
    top: -2px;
}

/* BUTTONS */

.bp3-button {
    letter-spacing: -0.02em;
}

.bp3-button.bp3-intent-primary {
    background-color: var(--active-color);
    background-image: none;
    box-shadow: none;
}

.bp3-button.bp3-intent-primary .bp3-button-text {
    font-weight: 600;
}

.bp3-button.bp3-intent-primary:not(.bp3-disabled):hover {
    background-color: var(--active-color-hover);
    box-shadow: none;
}

.bp3-button.bp3-intent-primary.bp3-disabled {
    background-color: var(--active-color);
    opacity: 0.65;
}

.bp3-button.bp3-intent-primary:disabled, 
.bp3-button.bp3-intent-primary.bp3-disabled {
    color: rgba(255, 255, 255, 0.9)
}

.bp3-button.bp3-small .bp3-button-text {
    font-size: 12px;
}

.bp3-button.bp3-intent-primary:active, 
.bp3-button.bp3-intent-primary.bp3-active {
    background-color: var(--active-color-pressed);
    box-shadow: none;
}

.bp3-button.bp3-intent-gray {
    background-color: rgb(235, 235, 235);
    border: none;
    box-shadow: none;
}

.bp3-button.bp3-intent-gray .bp3-button-text {
    font-weight: 600;
}


/* TOAST */

.bp3-toast-container.bp3-toast-container-inline {
    position: fixed;
    z-index: 100;
}

.bp3-toast {
    font-weight: 600;
    box-shadow: 0 0 0 1px rgb(16 22 26 / 5%), 0 2px 4px rgb(16 22 26 / 5%), 0 8px 24px rgb(16 22 26 / 10%);
    border-radius: 8px;
}

.bp3-toast.dark {
    background: #000;
    color: #fff;
}

.bp3-toast.dark > .bp3-icon {
    color: #fff;
}

.bp3-toast.dark .bp3-button .bp3-icon {
    color: #fff;
}

.bp3-toast-message {
    padding: 11px 16px;
}

.bp3-toast.bp3-intent-success {
    background-color: var(--success);
    color: #000;
    box-shadow: none;
    /* text-align: center; */
}

.bp3-toast .bp3-button-group {
    display: none;
}

.bp3-button-group.bp3-intent-dark {
    gap:2px;
    background-color: #444;
    border-radius: 4px;
}

.bp3-button-group .bp3-button .bp3-icon {
    margin-right: 4px;
    opacity: 0.9;
}

/* .bp3-button-group .bp3-button.bp3-intent-dark:not(:last-of-type) {
    border-right: 2px solid #444;
} */

.bp3-button .bp3-icon {
    color: #333;
}

.bp3-button .bp3-icon svg {
    width: 12px;
}

.bp3-callout h4.bp3-heading {
    font-size: 14px;
    font-weight: 600;
}

.bp3-callout.bp3-intent-warning {
    background-color: rgba(255,175,34, 0.15);
}

.bp3-callout.bp3-intent-warning .bp3-icon:first-child,
.bp3-callout.bp3-intent-warning .bp3-heading {
    color: var(--warning-color);
}

.b3-input-group input.bp3-input ::-webkit-input-placeholder { /* Edge */
    color: red;
}
  
.b3-input-group input.bp3-input :-ms-input-placeholder { /* Internet Explorer 10-11 */
color: red;
}

.b3-input-group input.bp3-input ::placeholder {
    color: red !important;
}

.bp3-select-popover.bp3-minimal.dark,
.bp3-popover2.bp3-minimal.dark {
    position: relative;
    top: 4px;
    border-radius: 8px;
} 
  
.bp3-select-popover.dark .bp3-menu,
.bp3-popover2.dark .bp3-menu,
.bp3-menu.dark {
    background-color: #000;
    color: #fff;
}

.bp3-select-popover.dark .bp3-menu .bp3-menu-item.bp3-disabled {
    color: rgba(255,255,255,0.7) !important;
}

.bp3-html-select select, 
.bp3-select select,
.bp3-html-select select:hover, 
.bp3-select select:hover  {
    background-image: none;
    box-shadow: none;
    border: 1px solid #ddd;
    background-color: #fff;
}

.bp3-button {
    /* box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 2px 4px; */
    border-radius: 4px;
    font-weight: 600;
}

.bp3-button:not([class*="bp3-intent-"]):not([class*="bp3-minimal"]) {
    background-color: var(--offwhite);
    box-shadow: none;
    border: none;
    background-image: none;
}

.bp3-button:not([class*="bp3-intent-"]):not([class*="bp3-minimal"]):hover {
    /* background-color: #f6f6f6; */
    background-color: var(--offwhite-hover);
}

.bp3-button.font600 .bp3-button-text {
    font-weight: 600;
}

.bp3-overlay {
    z-index: 200;
}

.bp3-overlay-backdrop { 
    background-color:rgba(207, 207, 207, 0.55);
}

.cw-menu-popover {
    box-shadow: 0 1px 0px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.05);
    border: 1px solid #dddddd;
    /* border-radius: 8px; */
}

.cw-menu-popover,
.cw-menu-popover.bp3-popover .bp3-popover-content,
.cw-menu-popover.bp3-popover .bp3-popover-content .bp3-menu {
    border-radius: 8px;
}

/* Inline Menus */

.inline-menu {
    font-size: 12px;
    position: relative;
    top: 4px;
    /* transform: scale(1) translate(0, 4px); */
}

.inline-menu .bp3-menu {
    background-color: #000;
    color: #fff;
    min-width: 90px;
    border-radius: 0;
    padding: 6px 0;
}

.inline-menu .bp3-menu-item {
    padding: 2px 12px;
}

/* dialog transitions */

.bp3-overlay-backdrop.fade-enter {
    opacity: 0;
}

.bp3-dialog-container.fade-enter {
    opacity: 0;
    transform: translate(0, 16px);
}

.bp3-overlay-backdrop.fade-enter-active,
.bp3-overlay-backdrop.fade-enter-done {
    opacity: 1;
    transition: opacity 300ms;
}

.bp3-dialog-container.fade-enter-active,
.bp3-dialog-container.fade-enter-done {
    opacity: 1;
    transform: translate(0, 0);
    transition: opacity 200ms, transform 200ms ease-out;
}

.bp3-overlay-backdrop.fade-exit {
    opacity: 1;
}

.bp3-dialog-container.fade-exit {
    opacity: 1;
}

.bp3-overlay-backdrop.fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

.bp3-dialog-container.fade-exit-active {
    opacity: 0;
    transform: translate(0, 16px);
    transition: opacity 200ms, transform 200ms ease-in;
}

.bp3-divider {
    margin: 5px 0;
}

/* Responsive styles */

@media screen and (max-width: 600px) {
    .mobile-fill,
    .bp3-input-group.mobile-fill input {
        width: 100%;
    }
}