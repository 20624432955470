.CopyLink {
    font-size: 12px;
    margin: 0 0 8px;
    opacity: 0.6;
    font-weight: 600;
    background-color: transparent;
    border: none;
    padding: 0;
    transition: opacity 0.2s;
}

.CopyLink:hover {
    opacity: 0.8;
    cursor: pointer;
}