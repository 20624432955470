.Dashboard header {
    margin: 0 20px;
}

.Dashboard header {
    display: flex;
    align-items: center;
}

.Dashboard header h1 {
    margin-right: 16px;
}