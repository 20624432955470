.SnippetDocContainer {
    margin: 128px auto 192px;
}

.SnippetHorizontalContainer {
    width: 95vw;
    max-width: 950px;
}

.SnippetDocTextContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    gap: 16px;
}

.SnippetDocContainer > h1 {
    margin: 0 20px 4px;
    font-size: 40px;
    max-width: 750px;
    line-height: 1.25;
}

.SnippetDocMainColumn,
.SnippetProposalsPreview {
    display: inline-block;
    vertical-align: top;
}

.SnippetDocSidebar {
    flex-grow: 1;
    max-width: 248px;
    min-width: 224px;
}

.SnippetDocContainer .CopyLink {
    margin-left: 20px;
}

@media screen and (max-width: 740px) {
    .SnippetDocSidebar {
        display: none;
    }
}

/* @media screen and (max-width: 600px) {
    .SnippetHorizontalContainer {
        width: 100vw;
    }
} */