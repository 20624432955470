.ThreadContainer {
    padding: 0px;
    box-shadow: var(--double-shadow);
    border-radius: 8px;
    width: 320px;
    position: relative;
    left: 8px;
    background-color: #fff;
    z-index: 21;
}

.ThreadComments {
    overflow: scroll;
    max-height: 360px;
    padding: 20px 16px 0;
}

.ThreadContainer .Comment p {
    font-size: 14px;
}
