.AccountMenuButton {
    background-color: #fff;
    border: 0;
    padding: 4px 4px 4px 8px;
    border-radius: 4px;
    cursor: pointer;
    height: 24px;
    position: relative;
    font-size: 12px;
}

.AccountMenuButton:hover {
    background-color: rgb(242, 242, 242);
}

.AccountMenuButton.anonymous {
    background-color: #fff;
    opacity: 0.8;
}

.AccountMenuButton.anonymous:hover {
    opacity: 1;
}

.AccountMenuButton.anonymous svg {
    height: 20px;
    top: 2px;
    right: 3px;
    position: absolute;
}