.InvalidEmailsError {
    margin: 0px 0 10px;
    padding: 3px 10px 7px 0px;
    display: flex;
    flex-direction: row;
}

.InvalidEmailsError .ErrorText {
    font-weight: 600;
    color: red;
    margin-right: 10px;
    position: relative;
    top: 3px;
}