.CurrentResults {
    padding: 0px 20px 0;
}

.CurrentResults p {
    /* font-size: 12px; */
    margin: 0 0 4px;
}

.CurrentResults ul {
    /* font-size: 12px; */
    margin: 0 0 3px;
}

.CurrentResults ul {
    padding-left: 12px;
}