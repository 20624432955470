.StartWriting .DraftEditorContainer {
    padding: 16px 30px;
    height: 400px;
    margin: 0 auto;
    overflow: scroll;
}

.StartWriting > .bp3-form-group {
    margin: 0;
}

.StartWriting > .bp3-form-group .bp3-label {
    margin: 16px 60px 8px;
    display: none;
}

.StartWriting + .ModalBody {
    display: none;
}

