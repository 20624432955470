.UserProfile {
    padding: 0 0 0 20px;
}

.ProfileHeader {
    display: flex;
    gap: 48px;
}

.ProfilePic {
    width: 150px;
    height: 150px;
    background: #eee;
    border-radius: 200px;
    position: relative;
}

.ProfilePic .PlaceholderGraphic {
    position: absolute;
    width: 150px;
    text-align: center;
    opacity: 1;
    top: 34px;
}

.ProfilePic .PlaceholderGraphic svg {
    fill: #fff;
}

.ProfilePic img {
    object-fit: cover;
    width: 150px;
    height: 150px;
    border-radius: 200px;
}

.ProfileNameRow {
    display: flex;
    gap: 16px;
    align-items: center;
}

.ProfileNameRowBtns {
    position: relative;
    top: 4px;
}

.ProfileItem {
    display: inline-block;
    margin-right: 24px;
    font-size: 12px;
}

.ProfileItem a {
    color: var(--active-color);
}

.ProfileItem svg {
    position: relative;
    top: -2px;
}

.UserProfile .DocCards {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: flex-start;
}

.UserProfile .DocCards .DocCard {
    flex-grow: 1;
    width: calc(33%);
    max-width: calc(33% - 3px);
    /* flex-basis: 0; */
    height: auto;
}

.UserProfile .DocCards .DocCardContent {
    /* height: 240px; */
}

.ProfileSectionHeader {
    font-size: 12px;
    font-weight: 500;
    text-align: center;
}

.ProfileSectionHeader span {
    position: relative;
    z-index: 2;
    background-color: #fff;
    padding-right: 6px;
    /* color: rgba(0,0,0,0.7); */
}

.ProfileSectionHeader .line {
    width: 100%;
    height: 1px;
    background-color: #000;
    opacity: 0.2;
    position: relative;
    top: -8px;
    display: none;
}