.NewDocStepList {
    margin: 5px 0 5px -5px;
}

.NewDocStepBtn {
    text-align: center;
    background-color: #fff;
    border: none;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    vertical-align: middle;
    color: rgb(108, 108, 108);
}

.NewDocStepBtn.disabled {
    cursor: default;
}

.NewDocStepBtn .bp3-icon {
    /* display: block; */
    vertical-align: middle;
}

.NewDocStepBtn.activeStep {
    color: #000;
}

.NewDocStepBtn:not(.activeStep) .bp3-icon {
    opacity: 0.7;
}

.NewDocStepBtn.activeStep .bp3-icon {
    opacity: 1;
    color: var(--st-primary);
}

.NewDocStepBtn .bp3-icon svg {
    margin: 0 auto 4px;
}