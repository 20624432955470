.GlobalSpinner {
    margin-top: 44vh;
    margin-bottom: 12px;
}

.GlobalSpinner h1 {
    font-weight: 500;
    font-size: 48px;
    letter-spacing: -0.04em;
    text-align: center;
    position: relative;
    left: -7px;
    color: var(--active-color);
}

.GlobalSpinner .bp3-spinner .bp3-spinner-track {
    stroke: transparent;
}

.GlobalSpinner .bp3-spinner .bp3-spinner-head {
    stroke: #ccc;
}