.react-datepicker__input-container input {
    height: 30px;
    line-height: 30px;
    border: 0px;
    border-radius: 4px;
    padding: 0 10px;
    box-shadow: 0 0 0 0 rgb(19 124 189 / 0%), 0 0 0 0 rgb(19 124 189 / 0%), inset 0 0 0 1px rgb(16 22 26 / 15%), inset 0 1px 1px rgb(16 22 26 / 20%);
}

.react-datepicker {
    border: 0px;
    box-shadow: var(--soft-shadow);
    position: relative;
    top: -4px;
}

.react-datepicker__header {
    background-color: #fff;
    border-bottom: 1px solid #ddd;
}

.react-datepicker__current-month {
    font-size: 14px;
}

.react-datepicker__triangle {
    display: none;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected, 
.react-datepicker__month-text--keyboard-selected, 
.react-datepicker__quarter-text--keyboard-selected, 
.react-datepicker__year-text--keyboard-selected {
    background-color: var(--active-color);
}

.react-datepicker__day--keyboard-selected, 
.react-datepicker__month-text--keyboard-selected, 
.react-datepicker__quarter-text--keyboard-selected, 
.react-datepicker__year-text--keyboard-selected {
    background-color: var(--active-color);
    opacity: 0.7;
}

.react-datepicker__day--selected:hover {
    background-color: var(--active-color-hover);
}

.react-datepicker__day--keyboard-selected:hover, 
.react-datepicker__month-text--keyboard-selected:hover, 
.react-datepicker__quarter-text--keyboard-selected:hover, 
.react-datepicker__year-text--keyboard-selected:hover {
    opacity: 0.8;
}