.SnippetProposalHeader {
    margin: 32px 0 16px 20px;
    /* border-bottom: 1px dotted #ddd; */
}

.SnippetProposalHeader .ProposalMeta {
    margin-bottom: 0;
}

.SnippetProposal .ProposalActions {
    padding: 16px 0;
    border-radius: 8px;
    box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 2px 4px;
    max-width: 248px;
    min-width: 224px;

}

.ProposalActions {
    position: sticky;
    top: 144px;
}

.ProposalDescription {
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    padding: 16px 0;
    margin: 0 28px 24px 20px;
}

.ProposalAdminActions .AdminActions {
    padding: 0px 20px 15px;
}