.EditComment {
    background-color: rgb(245, 245, 245);
    border-radius: 3px;
    width: 100%;
}

.EditComment textarea {
    box-shadow: none;
    background-color: transparent;
    resize: none;
    border: none;
    min-height: 56px;
}

.EditComment textarea:hover,
.EditComment textarea:focus {
    box-shadow: none;
    border: none;
}

.EditComment .CommentControls {
    padding: 6px;
    /* border-top: 1px solid #eee; */
}

.EditComment .CommentControls {
    text-align: right;
}
