.AddDecisionMakers {
    margin-top: 10px;
}

.AddDecisionMakersPopover {
    position: relative;
    z-index: 3;
    top: -7px;
    left: 7px;
}

.AddDecisionMakersMenu {
    width: 275px;
}

.ChooseDecisionMakers h4 {
    padding: 12px 15px 12px;
    border-bottom: 1px solid #eee;
    /* background-color: #fff; */
    margin: 0 0 5px;
}

.ChooseDecisionMakers .UserList {
    padding: 10px 15px;
    max-height: 160px;
    overflow: scroll;
}

.AddByEmail {
    border-top: 1px solid #eee;
    padding: 10px 15px 3px;
}

.AddByEmail .bp3-form-group {
    display: inline-block;
    width: 192px;
    margin-right: 5px;
}

.AddNewFromNoMembers .bp3-form-group {
    display: inline-block;
    width: 230px;
    margin-right: 5px;
}