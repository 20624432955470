.DocProposals {
    margin-top: 24px;
}

.ProposalsControls .bp3-button {
    margin: 0 5px 0 0;
}

.ProposalCards {
    margin: 16px 0 32px 0;
}