.Join {
    width: 450px;
    margin: 150px auto;
}

.Join .SingleSignOnOptions {
    margin: 20px 0 25px;
}

.Join p,
.Join .bp3-label {
    font-size: 14px;
}