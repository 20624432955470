.CommentEditor {
    border-radius: 8px;
    background-color: #fff;
    margin: 8px 0px 24px 0px;
    gap: 4px;
}

.CommentEditor .InputBar {
    display: flex;
    gap: 12px;
}

.CommentEditor .CommentInputContainer {
    flex-grow: 2;
    background-color: rgb(245, 245, 245);
    border-radius: 4px;
}

.CommentEditor textarea {
    resize: none;
    height: 56px;
    padding: 8px 12px;
    margin: 0 0 6px 0; 
    border-radius: 4px;
    box-shadow: none;
    border: none;
    outline: none;
}

.CommentEditor textarea:focus {
    box-shadow: none;
    border: none;
}

.CommentEditor:not(.active) textarea {
    height: 40px !important; 
}

.CommentEditor .CommentInputContainer textarea {
    min-height: 44px;
    background-color: rgb(245, 245, 245);
}

.CommentEditor .CommentActions {
    /* padding: 6px; */
}