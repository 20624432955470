.Homepage {
    width: 100vw;
    overflow: hidden;
    padding-left: 4px;
    margin-bottom: -320px;
}

.Homepage #canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    mix-blend-mode: multiply;
    overflow: hidden;
}

.Homepage * {

    letter-spacing: -0.015rem;
    /* font-family: 'ABC Diatype Regular'; */
    /* color: #fff; */
}

.Homepage * .dia-bold {
    /* font-family: 'ABC Diatype Regular'; */
}

.Homepage ::selection {
    background-color: var(--active-color);
    color: #fff;
}

.Homepage .ProductStatus {
    display: flex;
    align-items: baseline;
    max-width: 600px;
    margin: 8px auto 32px;
    position: relative;
    z-index: 4;
    justify-content: space-around;
    
}

.Homepage .bp3-button .bp3-button-text {
    /* font-family: 'ABC Diatype Bold'; */
    padding-left: 16px;
    padding-right: 16px;
    /* font-size: 18px; */
}

.HomepageLogo {
    margin-bottom: 24px;
}

.HomepageLogo svg {
    position: relative;
    top: 4px;
    margin-right: 6px;
}

.HomepageText {
    margin-top: 14vh;
    max-width: 800px;
    margin-bottom: 24px;
    margin: 30vh auto 24vh;
    position: relative;
    z-index: 2;
    /* text-shadow: 0px 0px 4px #fff, 0px 0px 8px #fff, 0px 0px 8px #fff, 0px 0px 16px #fff, 0px 0px 40px #fff; */
}

.diatype {
    font-family: 'ABC Diatype Regular';
    font-weight: 400;

}

.HomepageText h1 {
    font-size: 72px;
    letter-spacing: -0.02em;
    line-height: 1.05;
    margin-bottom: 0px;
    margin-top: 0;
    pointer-events: none;
    font-family: 'ABC Diatype Bold';
    font-weight: 400;
    max-width: 600px;
}

.HomepageText h1 span {
    font-size: 36px;
    position: relative;
    top: -24px;
    left: 4px;
    font-weight: 600;
    display: none;
}

.HomepageText h2 {
    font-weight: 400;
    font-size: 32px;
    color: #555;
    letter-spacing: -0.01em;
}

.HomepageText p {
    font-size: 21px;
    line-height: 1.35;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.HomepageText p b {
    font-family: 'ABC Diatype Bold';
    font-weight: 400;
}

.RequestInvite {
    width: 400px;
    display: flex;
}

.RequestInvite .bp3-button,
.RequestInvite .bp3-button:hover {
    background: #000;
    color: #fff;
    text-align: center;
}

.RequestInvite .bp3-button .bp3-button-text {
    font-size: 18px;
}

/* .RequestInvite .bp3-button .bp3-button-spinner {
    margin: 0 34.5%;
} */

.RequestInvite .bp3-input-group input {
    box-shadow: 0 2px 6px rgba(0,0,0,0.05);
    border: 1px solid #ccc;
    width: 272px;
    padding: 0 12px;
    transition: border 0.1s, box-shadow 0.1s;
    font-size: 18px;
    background-color: #fff;
}

.RequestInvite .bp3-input-group input:hover {
    border: 1px solid #bbb;
}

.RequestInvite .bp3-input-group input:focus {
    border: 1px solid #999;
    outline: 2px solid #999;
    box-shadow: 0px 4px 8px rgba(157, 157, 157, 0.25);
}

.RequestInvite .bp3-input-group input::placeholder {
    color: #999;
}

.RequestInvite .bp3-button.bp3-intent-primary.bp3-disabled {
    opacity: 0.75;
}

.RequestInvite .bp3-button .bp3-button-text {
    font-weight: 600;
}

.RequestInvite .bp3-button:focus {
    outline: 2px solid var(--active-color);

}

.Homepage form {
    display: flex;
}

.Lines {
    position: fixed;
    top: 0;
    left: 5%;
    mix-blend-mode: multiply;
    width: 90vw;
    height: 100vh;
    pointer-events: none;
    color: #fb7385;
}

.Lines svg {
    height: 92vh;
    position: relative;
    top: 2vh;
}

.SmallText {
    margin-top: 48px;
}

.SmallText p {
    font-size: 16px;
    opacity: 0.8;
}

@media screen and (max-width: 550px) {
    .Lines {
        left: -100%;
        top: 25px;
        opacity: 0.5;
        /* display: none; */
    } 
    .HomepageText {
        margin-top: 4vh;
        max-width: 90%;
    }
    
    .HomepageText h1 {
        font-size: 36px;
    }

    .HomepageText h1 span {
        font-size: 24px;
    }

    /* .RequestInvite .bp3-button {
        margin: 16px 0 0 0;
    }

    .RequestInvite .bp3-button-spinner {
        margin: 0 35%;
    } */
}

@media screen and (max-width: 600px) {
    .RequestInvite {
        flex-direction: column;
    }
}