.ThreadAddComment {
    display: flex;
    gap: 12px;
    padding: 12px 16px 12px;
    border-top: 1px solid #eee;
}

.ThreadAddComment .avatar {
    position: relative;
    top: 7px;
}

.ThreadAddCommentBox {
    position: relative;
    display: flex;
    background-color: rgb(245, 245, 245);
    border-radius: 4px;
    flex-grow: 2;
}

.ThreadAddComment .ThreadAddCommentBox textarea {
    border: none;
    resize: none;
    background-color: rgb(245, 245, 245);
    box-shadow: none;
    font-size: 14px;
    max-height: 50vh;
    overflow: scroll;
}

.ThreadAddComment .ThreadAddCommentBox:not(.active) textarea {
    height: 37px !important; 
}

.ThreadAddComment .ThreadAddCommentBox.active textarea {
    min-height: 44px;
}

.ThreadAddCommentBox.active {
    display: block;
}

.ThreadAddCommentBox:not(.active) .SendCommentBtn {
    position: relative;
    top: 6px; 
    right: 6px;
}

.ThreadAddCommentBox.active .CommentControls {
    padding: 6px;
    border-top: 1px solid #eee;
}

.ThreadAddCommentBox.active .CommentControls {
    text-align: right;
}
