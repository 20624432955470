.ModalTopBar {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 4px 4px 4px 12px;
    align-items: center;
}

.ModalTopBar.minimal {
    border-bottom: 1px solid transparent;
}

.ModalTopBar .ModalTitle {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.015rem;
}