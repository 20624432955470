.EditDocBtn {
    margin: 10px 8px 20px 20px;
}

.ForkBtn {
    margin: 10px 12px 20px 0px;
}

.LastEdited {
    position: relative;
    top: -4px;
    font-size: 12px;
    opacity: 0.6;
}

.ArweaveLogo {
    opacity: 0.6;
}

.ArweaveLogo:hover {
    opacity: 1;
}