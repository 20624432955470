.DocCard {
    
    padding: 8px 20px 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 3px rgba(0,0,0,0.075);
    background-color: #fff;
    transition: background-color 0.3s;
}

.DocCardContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.DocCard:hover {
    background-color: rgba(0,0,0,0.025)
}

.DocCardContent .LastEdited {
    opacity: 1;
}

.DocCard .UpperCardGroup {
    margin-bottom: 32px;
}

.DocCard .UpperCardGroup h3 {
    font-size: 12px;
    letter-spacing: -0.01em;
    /* font-weight: 00; */
    line-height: 1.1;
}

.DocCard .LowerCardGroup p {
    font-size: 12px;
    margin: 0;
    opacity: 0.8;
    line-height: 1.4;
}