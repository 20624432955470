.DocEditorModal .DraftEditor-root {
    max-height: calc(88vh - 89px);
    min-height: 250px;
    overflow: scroll;
    /* border: 2px solid #eee;
    border-radius: 6px; */
    padding: 20px 0px;
    margin-bottom: 15px;
    line-height: 1.6;
    transition: border 0.3s;
}

.DraftEditorContainer {
    line-height: 1.6;
    position: relative;
}

.DraftEditorContainer *::selection {
    background: var(--active-color);
    color: #fff;
}

.public-DraftEditorPlaceholder-root {
    padding: 0 30px;
}

.DocEditorModal .DraftEditor-editorContainer {
    border-left: none;
}

.public-DraftStyleDefault-block {
    padding: 0px 30px;
}

.st-highlighted-block {
    background-color: rgb(255, 238, 171);
    animation-name: highlightFade;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-delay: 0.75s;
}

.DraftEditorContainer blockquote {
    border-left: 3px solid #ddd;
    margin: 8px 32px;
}

.DraftEditorContainer blockquote .public-DraftStyleDefault-block {
    padding: 0 24px;
}

@keyframes highlightFade {
    from {
        background-color: rgb(255, 238, 171);
    }
  
    to {
        background-color: rgb(255, 255, 255);
    }
}

/* List Styles */

ol.public-DraftStyleDefault-ol {
    margin: 4px 0 4px 44px;
}

.public-DraftStyleDefault-orderedListItem {
    padding-left: 24px;
}

ul.public-DraftStyleDefault-ul {
    margin: 4px 0 4px 51px;
}

.public-DraftStyleDefault-unorderedListItem {
    padding-left: 17px;
}

.public-DraftStyleDefault-orderedListItem .public-DraftStyleDefault-block,
.public-DraftStyleDefault-unorderedListItem .public-DraftStyleDefault-block {
    padding-left: 0;
} 

.DraftEditorContainer .public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
    text-align: left;
    left: -8px;
}

/* Heading Styls */

.DraftEditorContainer h1 {
    font-size: 1.7rem;
    letter-spacing: -0.03rem;
    margin: 0;
    line-height: 1.4;
}

.DraftEditorContainer h2 {
    font-size: 1.35rem;
    margin: 0;
    letter-spacing: -0.01rem;
}

.DraftEditorContainer h3 {
    font-size: 1.15rem;
    margin: 0;
    letter-spacing: -0.02rem;
}