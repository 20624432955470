.CommentReplies {
    border-left: 1px solid #eee;
    padding-left: 24px;
}

.CommentRepliesLoading {
    justify-content: start;
    margin-top: 4px;
}

.CommentReplies .Comment:last-of-type {
    padding-bottom: 0;
}