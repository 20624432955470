.Team {
    width: 590px;
    margin: 8em auto 10em;
}

.Team .TeamHeader {
    margin-bottom: 25px;
    vertical-align: top;
    display: flex;
    justify-content: space-between;
}

.Team .TeamHeader .TeamControls {
    padding-top: 8px;
}

.TeamDocs {
    margin: 24px 20px;
}

.TeamDocs .DocIndexItem {
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
}

.TeamDocs .DocIndexItem:last-of-type {
    border-bottom: none;
}