.ProtocolOption {
    margin: 8px 0;
}

.ProtocolOption .ProtocolChoice {
    background-color: transparent;
    border: none;
    display: block;
    padding-left: 0;
    cursor: pointer;
    margin: 8px 0 4px;
}

.ProtocolOption .RadioIcon {
    width: 16px;
    height: 16px;
    display: inline-block;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    vertical-align: top;
    margin-right: 8px;
    position: relative;
    top: 1px;
}

.ProtocolOption .RadioIcon.selected {
    background-color: var(--active-color);
    border: 1px solid var(--active-color);
}

.ProtocolOption .ProtocolChoice:hover .RadioIcon:not(.selected) {
    background: #fafafa;
    border: 1px solid #ccc;
}