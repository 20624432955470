
.MiniProposalCard {
    /* border-radius: 12px; */
    font-size: 12px;
    text-decoration: none !important;
}

a.MiniProposalCard:hover h4 {
    text-decoration: none;
}

.MiniProposalCard h4 {
    margin: 0 0 2px;
}