.LinkEditorPortal {
    z-index: 99;
}

.LinkEditorModal .LinkInputBar {
    display: flex;
    gap: 4px;
}

.LinkEditorModal .LinkInputBar .bp3-button {
    width: 80px;
}