.DecisionMakerList {
    margin-top: 5px;
}

.DecisionMakerList .DecisionMaker {
    padding: 5px 0;
    border-bottom: 1px dotted #ccc;
    display: flex;
    justify-content: space-between;
}

.DecisionMakerList .DecisionMaker:first-of-type {
    border-top: 1px dotted #ccc;
}

.DecisionMakerList .DecisionMakerLabel {
    margin-top: 6px;
}

.DecisionMakerList .DecisionMakerLabel .NewDecisionMaker {
    margin-left: 5px;
    color: var(--st-primary-hover);
}