.Comment {
    display: flex;
    gap: 12px;
    padding-bottom: 16px;
}

.Comment .text {
    position: relative;
    flex-grow: 2;
}

.CommentMeta {
    display: flex;
    justify-content: space-between;
}

.CommentMenu {
    position: absolute;
    top: -3px;
    right: 2px;
}

.comment-timestamp {
    z-index: 22;
}

.CommentMenuPopover {
    z-index: 22;
}

.Comment .ReferenceTextContainer {
    margin: 12px 0 4px;
    width: 100%;
    display: flex;
    align-items:flex-start;
    cursor: pointer;
}

.Comment .ReferenceLine {
    border-top: 1px solid #eee;
    border-left: 1px solid #eee;
    border-radius: 6px 0 0 0;
    display: inline-block;
    width: 14px;
    height: 12px;
    margin: -7px 4px 0 0;
}

.Comment .ReferenceText {
    font-size: 12px;
    font-style: italic;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 30%;
    max-width: 700px;
    margin: -16px 0 4px 0;
    opacity: 0.8;
}

.Comment .ReferenceText:hover {
    opacity: 1;
}

.Comment .ReferenceTextContainer.expanded .ReferenceText {
    text-overflow: initial;
    white-space: normal;
    width: 80%;
}