.SideNavBtn {
    border: 1px solid transparent
}

.SideNavBtn:hover {
    background-color: var(--offwhite-hover);
}

.SideNavBtn.active {
    background-color: #fff;
    border: 1px solid #e1e1e1;
    box-shadow: 0 2px 2px rgba(0,0,0, 0.05);
}

.SideNavBtn.active svg {
    opacity: 0.9;
}