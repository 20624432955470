.UserMultiSelectList {
    max-height: 200px;
    overflow: scroll;
    width: 424px;
}
.UserMultiSelectList.hidden {
    display: none;
}

.select-list-item {
    width: 100%;
    padding: 8px 8px;
    cursor: pointer;
    border-radius: 3px;
    font-size: 12px;
}

.select-list-item:hover {
    background-color: var(--white-hover);
}

.select-list-item.active {
    background-color: var(--active-color);
    color: #fff;
}

.bp3-tag.UserTag {
    border-radius: 4px;
    background-color: #6f6868;
    color: #fff;
}

.bp3-tag.UserTag.invalid {
}

.UserTag .bp3-tag-remove {
    position: relative;
    top: 1px;
}

.UserTag .bp3-tag-remove svg {
    width: 13px;
    height: 13px;
}