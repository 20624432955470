.ViewToggle {
    background-color: var(--offwhite-hover);
    padding: 2px 5px;
    border-radius: 24px;
    transition: background-color 0.3s;
}

.ViewToggle svg {
    opacity: 0.7;
}

.ViewToggle:hover svg {
    opacity: 1;
}

.ViewToggle svg {
    fill: #999;
}