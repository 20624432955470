.SignUpPortal {
    z-index: 22;
}

.SignUpModal {
    width: 440px;
    border-radius: 16px;
}

.SignUpModal h1 {
    margin: 0;
}

.SignUpModal .ErrorList {
    margin: 0 0 16px 0;
}

.SignUpModal .SignInError {
    color:  #000;
    margin: 4px 0;
    width: 100%;
}

.SignUpModal .SignInError .ErrorBody {
    display: block;
    padding: 4px 10px;
    background: #eee;
    border-radius: 4px;
}

.SignUpModal .SignInError .ErrorBody .ErrorIcon,
.SignUpModal .SignInError .ErrorBody .ErrorText {
    display: inline-block;
    vertical-align: middle;
}

.SignUpModal .SignInError .ErrorBody .ErrorIcon {
    margin-right: 8px;
    font-size: 18px;
    vertical-align: top;
}

.SignUpModal .SignInError .ErrorBody .ErrorText {
    width: calc(100% - 24px);
    margin: 2px 0;
}

.SignUpModal .SocialSignIn {
    margin: 24px 0;
}