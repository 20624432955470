.TextBlockMenu {
    position: absolute;
    top: -6px;
    right: 12px;
    /* padding: 0 4px; */
    background: #fff;
    /* border: 1px solid #ccc; */
    border-radius: 8px;
    box-shadow: var(--soft-shadow);
    opacity: 1;
    transform: rotate(0deg);
    transition: 0.2s opacity, 0.2s top, 0.3s transform;
}

.TextBlockMenu.hidden {
    opacity: 0;
    pointer-events: none;
    transform: rotate(-10deg);
    top: -5px;
}

.TextBlockMenu .bp3-button {
    min-width: 12px;
    min-height: 12px;
}

.TextBlockMenu .bp3-button:first-of-type {
    padding-left: 11px;
}

.TextBlockMenu .bp3-button:last-of-type {
    padding-right: 11px;
}

.TextBlockMenu .bp3-button .bp3-icon svg {
    width: 10px;
    height: 10px;
}
