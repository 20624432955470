@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/popover2/lib/css/blueprint-popover2.css";

:root {
  --st-primary: #4362fc;
  --st-primary-hover: #3555f7;
  --st-primary-disabled: #7388f1;
  --success: #b0e6cf;
  --neutral-dark: #4A4545;
  --neutral-dark-hover: hsl(0, 5%, 24%);
  --interface-blue: #caddec;
  /* --active-color:  rgb(78, 110, 253); 
  --active-color-hover: rgb(45, 83, 253); 
  --active-color-pressed:  rgb(31, 69, 238);  */
  --active-color:  #725c9d; 
  --active-color-hover: hsl(260, 32%, 47%); 
  --warning-color: #ffa7a7;
  --dark-warning-color: #ff5a5a;
  --white-hover: rgb(238, 238, 239);
  --success-bg: rgb(195, 252, 208);
  --fail-bg: rgb(253, 236, 232);
  --soft-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 2px 4px;
  --double-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 20%) 0px 4px 6px;
  --mobile-breakpoint: 600px;
  --offwhite: #f4f3f1;
  --offwhite-active: #e3dfd8;
  --offwhite-hover: #ebe7e1;
  --neutral-light: #ebe7e1;
  --neutral-light-hover: hsl(0, 6%, 31%);
  --offblack: rgb(26, 26, 32);
}

.accent-text {
  color: var(--active-color);
}

.text-warning {
  color: var(--dark-warning-color)
}

.bg-accent { background-color: var(--active-color); }
.bg-warning { background-color: var(--warning-color); }
.bg-neutral-dark { background-color: var(--neutral-dark); }
.bg-neutral-light { background-color: var(--neutral-light); }
.bg-offwhite { background-color: var(--offwhite); }
.bg-offwhite-dark { background: var(--offwhite-hover); }

.fill-neutral-dark { fill: var(--neutral-dark); }
.fill-neutral-light { fill: var(--neutral-light); }

.TopMenu {
  position: fixed;
  top: 10px;
  right: 10px;
}

.st-divider-space {
  margin: 25px 0;
}

.st-divider-small-space {
  margin: 20px 0;
}

.st-padded-btn {
  padding: 9px 20px;
}

.st-page-side-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.st-black-txt,
.st-black-txt:hover {
  color: #000;
}

.st-para {
  line-height: 1.6;
}

.cursor-default, .cursor-default * {
  cursor: default;
}

.primary-color {
  color: var(--st-primary);
}

.st-accepted-intent {
  background-color: #7c15c7;
  color: #fff;
}

.st-accepted-intent .bp3-icon svg {
  fill: #fff;
}

.st-inline-callout {
  display: inline-block;
  width: auto;
  font-weight: 600;
  background-color: #fff;
  border: 2px solid rgb(231, 231, 231);
  border-radius: 8px;
}

.st-inline-callout .bp3-icon svg {
  fill: var(--st-primary);
}

.st-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.bp3-button.bp3-intent-success {
  background-color: var(--success);
  /* background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(0, 76, 32, 0.25)); */
  box-shadow: none;
  color: #000;
}

.bp3-button.bp3-intent-neutral-dark {
  background-color: var(--neutral-dark);
  box-shadow: none;
  color: #fff;
}

.bp3-button.bp3-intent-neutral-dark:hover {
  background-color: var(--neutral-dark-hover);
} 

.bp3-button.bp3-intent-neutral-light {
  background-color: var(--offwhite-hover);
  box-shadow: none;
  color: #000;
}

.bp3-button.bp3-intent-neutral-light:hover {
  opacity: 0.85;
} 

.bp3-button.bp3-intent-danger {
  background-color: #ed5208;
  /* background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(70, 0, 0, 0.2)); */
  box-shadow: inset 0 0 0 1px rgb(16 22 26 / 20%), inset 0 -1px 0 rgb(16 22 26 / 10%);
  color: #ffffff;
}

.bp3-button.bp3-intent-dark {
  background-color: var(--offblack);
  /* background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(70, 0, 0, 0.2)); */
  box-shadow: inset 0 0 0 1px rgb(16 22 26 / 20%), inset 0 -1px 0 rgb(16 22 26 / 10%);
  color: #ffffff;
}

.bp3-button.bp3-intent-dark:hover {
  background-color: var(--offblack-hover);
}

.bp3-button.bg-white {
  background-color: #fff;
}

.bp3-spinner .bp3-spinner-track {
  stroke: rgba(92, 112, 128, 0.1);
}

.bp3-spinner .bp3-spinner-head {
  stroke: rgb(0 0 0 / 70%);
}

.bp3-control {
  margin-bottom: 8px;
}

.bp3-control .bp3-control-indicator,
.bp3-numeric-input .bp3-button {
  background-color: #fff;
  background-image: none;
}

.bp3-control:hover .bp3-control-indicator,
.bp3-numeric-input .bp3-button:hover {
  background-color: rgb(246, 246, 246);
}

.bp3-control input:checked ~ .bp3-control-indicator,
.bp3-control:hover input:checked ~ .bp3-control-indicator {
  background-color: var(--active-color);
}

.bp3-control input:checked ~ .bp3-control-indicator,
.bp3-control input:checked:hover ~ .bp3-control-indicator {
  background-color: none;
  background-image: none;
  box-shadow: none;
}

.bp3-label .bp3-text-muted {
  font-weight: 400;
  color: #555;
}

.NonIdealBlock {
  padding: 40px 50px;
  margin: 15px 0;
  border-radius: 8px;
  background-color: rgba(0,0,0,0.03);
}

.NonIdealBlock h4.bp3-heading {
  font-size: 20px;
  margin-bottom: 13px;
}

.st-more-info-underline {
  border-bottom: 2px dotted var(--st-primary-disabled);
}

.st-more-info-underline:hover {
  border-bottom: 2px dotted var(--st-primary-hover);
}

.content-card {
  padding: 12px 22px;
  box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 2px 4px;
  border-radius: 8px;
  margin: 12px 0;
}

.std-shadow {
  box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 2px 4px;
  transition: box-shadow 0.2s;
}

a:hover .std-shadow {
  box-shadow: rgb(15 15 15 / 15%) 0px 0px 0px 1px, rgb(15 15 15 / 20%) 0px 2px 4px;
}

.card-padding {
  padding: 4px 12px;
}

.card-radius {
  border-radius: 4px;
}

.content-card.no-hover:hover {
  cursor: auto;
  box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 2px 4px;
}

.content-card:hover {
  cursor: pointer;
  box-shadow: rgb(15 15 15 / 15%) 0px 0px 0px 1px, rgb(15 15 15 / 15%) 0px 2px 4px;
}

a .content-card:hover {
  text-decoration: none;
}

a.no-deco {
  text-decoration: none;
}

.content-card h3,
.content-card p {
  margin: 4px 0;
}

.content-card p.no-margin {
  margin: 0;
}

.bp3-button.primary-neutral-btn {
  color: #333;
  background-color: #fff;
  box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 2px 4px;
}

.bp3-button.primary-neutral-btn.active {
  background-color: #eee;
}

.bp3-button.bp3-small.primary-neutral-btn .bp3-button-text {
  font-size: 12px; 
  font-weight: 600;
}

.bp3-button.primary-neutral-btn.bp3-disabled {
  background-color: rgb(245, 246, 250);
  color: #999;
}

.bp3-button.primary-neutral-btn:not(.bp3-disabled):hover {
  box-shadow: none;
  background-color: #fff;
}

.bp3-button.primary-neutral-btn.active:hover {
  box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 2px 4px;
  background-color: #ededed;
}

.bp3-button.primary-neutral-btn .bp3-button-text {
  font-weight: 400;
}

.bp3-button.fill-btn {
  color: #333;
  background-color: var(--offwhite);
  box-shadow: none;
}

.bp3-button.fill-btn:hover {
  color: #333;
  background-color: var(--offwhite-hover);
  box-shadow: none;
}

.bp3-button.neutral-minimal {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(237, 237, 237);
  color: #777;
  box-shadow: none;
}

.bp3-button.neutral-minimal:hover {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(225, 225, 225);
  color: #777;
  box-shadow: none;
}

.bp3-button.neutral-minimal .bp3-button-text {
  font-weight: 400;
}

.small-text {
  font-size: 12px;
}

.large-text {
  font-size: 18px;
  line-height: 1.45;
  font-weight: 400;
}

.soft-text {
  opacity: 0.8;
}

.half-opaque {
  opacity: 0.6;
}

.btn-padding {
  padding: 8px 24px;
}

.minimal-card {
  padding: 20px 0;
  border-bottom: 1px solid #ddd;
  box-shadow: none;
  margin: 0;
}

.book-end {
  font-size: 12px;
  text-align: center;
  margin: 24px 0;
  opacity: 0.8;
}

.bold {
  font-weight: 600;
}

.NonIdealFullPage h4.bp3-heading {
  font-size: 32px;
  margin-bottom: 32px;
  max-width: none;
}

.no-margin {
  margin: 0;
}

.text-center {
  text-align: center;
}

.font700,
.font700 *,
.bp3-button.font700 .bp3-button-text {
  font-weight: 700;
}

.font600,
.font600 *,
.bp3-button.font600 .bp3-button-text {
  font-weight: 600;
}

.font500,
.font500 *,
.bp3-button.font500 .bp3-button-text {
  font-weight: 500;
}

.font400,
.font400 *,
.bp3-button.font400 .bp3-button-text {
  font-weight: 400;
}

.margin-top-0 { margin-top: 0 }

.margin-top-2 {
  margin-top: 2px;
}

.margin-top-4 { margin-top: 4px }

.margin-top-16 { margin-top: 16px }
.margin-top-24 { margin-top: 24px }
.margin-top-32 { margin-top: 32px }
.margin-top-48 { margin-top: 48px }
.margin-top-64 { margin-top: 64px }

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-bottom-32 {
  margin-bottom: 32px;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.margin-bottom-12 {
  margin-bottom: 12px;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-4 {
  margin-bottom: 4px;
}

.margin-bottom-0 {
   margin-bottom: 0;
}

.margin-left-4 {
  margin-left: 4px;
}

.margin-left-8 {
  margin-left: 8px;
}

.margin-left-12 {
  margin-left: 12px;
}

.margin-left-16 {
  margin-left: 16px;
}

.margin-left-20 { margin-left: 20px; }
.margin-right-20 { margin-right: 20px; }

.margin-right-8 {
  margin-right: 8px;
}

.margin-right-4 {
  margin-right: 4px;
}

.margin-right-12 { margin-right: 12px; }
.margin-right-16 { margin-right: 16px; }

.font-size-12 {
  font-size: 12px;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-20 {
  font-size: 20px;
}

.padding-top-bottom-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.padding-top-8 { padding-top: 8px; }
.padding-top-32 { padding-top: 32px; }
.padding-bottom-8 { padding-bottom: 8px }
.padding-bottom-24 { padding-bottom: 24px }
.padding-bottom-32 { padding-bottom: 32px; }

.soft-border-top {
  border-top: 1px solid #ddd;
}

.soft-border-bottom {
  border-bottom: 1px solid #ddd;
}

.soft-border-dotted-bottom {
  border-bottom: 1px dotted #ddd;
}

.padded-block-sides {
  margin-left: 20px;
  margin-right: 28px;
}

.margin-bottom-2 {
  margin-bottom: 2px;
}

.margin-bottom-4 {
  margin-bottom: 4px;
}

.margin-right-4 {
  margin-right: 4px;
}

.line-height-1-1 {
  line-height: 1.1;
}

.line-height-1-4 {
  line-height: 1.4;
}

.width-75-percent {
  width: 75%;
}

.width-67-percent {
  width: 66.7%;
}

.width-50-percent {
  width: 50%;
}

.width-25-percent {
  width: 25%;
}

.pre-wrap {
  white-space: pre-wrap;
}

.bp3-button.secondary-btn,
.bp3-button.secondary-btn:hover {
  border-radius: 0;
  border: 1px solid #ddd;
  box-shadow: none;
  background: #fff;
}

.bp3-button.secondary-btn .bp3-button-text {
  /* font-weight: 400; */
  /* font-size: 1px; */
}

.bp3-button.secondary-btn:hover {
  cursor: pointer;
}

.bp3-button.text-btn {
  padding: 0;
  min-height: 0;
  opacity: 1;
  box-shadow: none;
  background: none;
  border: 0px;
}

.bp3-button.text-btn:hover,
.bp3-button.text-btn.bp3-active {
  opacity: 1;
  background-color: transparent;
  box-shadow: none;
  font-weight: 400;
}

.bp3-button.text-btn .bp3-button-text {
  font-weight: 600;
}

textarea.bp3-input::placeholder {
  color: #555;
}

.cw-modal {
  background-color: #fff;
  border-radius: 4px;
  padding-bottom: 0;
  box-shadow: 0 1px 0px rgba(0,0,0,0.1);
  border: 1px solid #ddd;
  font-size: 12px;
}

.cw-modal-body {
  max-height: 70vh;
  overflow: scroll;
}

.modal-padded-section {
  padding: 8px 12px;
}

.modal-deep-padded-section {
  padding: 8px 64px;
}

.modal-bottom-padding {
  padding-bottom: 48px;
}

.cw-modal .bp3-html-select select, .bp3-select select {
  font-size: 12px;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.fill {
  width: 100%;
}

.inline-numeric,
.inline-numeric input.bp3-input {
  text-align: center;
  width: 32px;
  padding-left: 0;
  padding-right: 0;
}

.dia-bold {
  /* font-family: 'ABC Diatype Bold'; */
  font-weight: 400;
}

.vis-hidden { visibility: hidden; }

.z-300, .bp3-overlay.z-300 { position: relative; z-index: 300; }

.skeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e8e8e8 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: skeleton-loading 4s infinite;
}

@keyframes skeleton-loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@media screen and (max-width: 600px) {
  .mobile-margin-bottom-12 {
    margin-bottom: 12px;
  }
}