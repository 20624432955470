.ProposalBalance {
    display: inline-block;
    vertical-align: top;
    margin: 0px 0px 0px 4px;
    vertical-align: top;
}

.ProposalBalance .VoteIndicator {
    margin-right: 4px;
    display: inline-block;
    font-size: 12px;
}