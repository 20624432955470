.DateBlock {
    margin-bottom: 32px;
}

.EventBlock {
    display: flex;
    gap: 20px;
    margin-bottom: 16px;
    max-width: 720px;
    padding-bottom: 16px;
    border-bottom: 1px solid #eee;
}

.EventBlock:last-of-type {
    border-bottom: none;
}

.EventInfo {
    flex-grow: 1;
}

.EventIcon {display: none;}

.activity-tag-link {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 3px rgba(0,0,0,0.075);
    font-size: 11px;
    padding: 8px 8px;
    border-radius: 4px;
    font-weight: 500;
}

.ProposalTag.activity-tag-link {
    padding: 7px 4px 7px 10px;
}

.event-card {
    background-color: #fff;
    border-radius: 4px;
    padding: 12px 16px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 3px rgba(0,0,0,0.075);
}

a .event-card *:not(.bp3-tag):hover {
    text-decoration: underline;
}

.event-quote {
    border-left: 1px solid #eee;
    padding: 8px 24px;
    margin-bottom: 8px;
}

.event-card.asymmetric {
    padding: 12px 40px 12px 16px;
}

.activity-tag-link .bp3-tag {
    font-size: 11px;
    padding: 0px 6px;
    border-radius: 12px;
    font-weight: 500;
    line-height: 1;
    margin-left: 8px;
}